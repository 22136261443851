import React from 'react';
import ClikCalculator from './ClikCalculator';

function App() {
  return (
    <div className="App">
      <ClikCalculator/>
    </div>
  );
}

export default App;
