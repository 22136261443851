import React, { useEffect, useState } from 'react';
import './clik-calculator.css';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const ClikCalculator = function(props){
    var [selectableProducts,setSelectableProducts] = useState([]);
    var [totalUpfront,setTotalUpfront] = useState(0);
    var [totalAnnualCover,setTotalAnnualCover] = useState(0);
    var [totalUsers,setTotalUsers] = useState(0);
    var [isDisplayingMonthly,setIsDisplayingMonthly] = useState(false);
    var switchStyle = withStyles((theme)=>({
        thumb: {
            color:"red"
          }
    }));
    useEffect(()=>{    
        setSelectableProducts(getSelectableProductsFromWordpress());
    },[]);

    function getSelectableProductsFromWordpress(){
       var globalProductSettings = getGlobalCalculatorSettings();
       
       var search = window.location.search;
       var params = new URLSearchParams(search);
       var filterProducts = params.get('filterProducts');
       

       var isFilteringProducts = filterProducts!= null && filterProducts != "";
       var displayingProducts = [];
       if (isFilteringProducts){
           displayingProducts = filterProducts.toLowerCase().split(",");
       }
       var selectableProducts = [];
       for (var i = 0; i < globalProductSettings.length;i++){
            var product = globalProductSettings[i];
            if (!isFilteringProducts || displayingProducts.indexOf(product.name.toLowerCase())>-1 ){
                product.index = i;
                for(var j = 0; j <product.licences.length;j++){
                    var indexedLicence = product.licences[j];
                    indexedLicence.index = j;
                    indexedLicence.selectedUsers = 0;
                    indexedLicence.calculatedUpfrontFee = 0;
                    indexedLicence.calculatedCoverFee = 0;
                }
                selectableProducts.push(product);
            }
       }
       return selectableProducts;
    }   

    function getGlobalCalculatorSettings(){
        return[
                {
                   name:"Clik Service",
                   color:"#ED7228",
                   hasFirstTwoLicenceAnnualFee:true,
                   firstTwoLicenceAnnualFee:360,
                   isHighlighted:true,
                   licences:[
                        {
                            name:"Standard",
                            upfrontFee:350,
                            annualFee:120 ,                            
                            subtext:"Initial licence £750, additional £350",
                            setupFee:400                         
                        }
                  ]
                },
                {
                    name:"Clik Jobs",
                    color:"#6eb542",                    
                    hasFirstTwoLicenceAnnualFee:false,
                    firstTwoLicenceAnnualFee:0,
                    setupFee:0,
                    licences:[
                         {
                             name:"Engineer",
                             upfrontFee:100,
                             annualFee:60,
                             setupFee:0
                         }
                   ]
                 } ,
                 {
                    name:"Clik Remote",
                    color:"#1fa58c",                    
                    hasFirstTwoLicenceAnnualFee:false,
                    firstTwoLicenceAnnualFee:0,
                    
                    licences:[
                        {
                            name:"Manager",
                            upfrontFee:350,
                            annualFee:120,
                            setupFee:0
                        },
                        {
                            name:"Engineer",
                            upfrontFee:150,
                            annualFee:60,
                            setupFee:0
                        },
                         {
                             name:"Customer",
                             upfrontFee:150,
                             annualFee:60,
                             setupFee:0
                         },
                         {
                            name:"Dashboard",
                            upfrontFee:100,
                            annualFee:60,
                            setupFee:0
                        },
                        {
                            name:"Sales",
                            upfrontFee:150,
                            annualFee:60,
                            setupFee:0
                        },
                        
                        
                   ]
                 },
                 {
                    name:"Clik Cert",
                    color:"#3080AB",                    
                    hasFirstTwoLicenceAnnualFee:false,
                    firstTwoLicenceAnnualFee:0,
                    licences:[
                         {
                             name:"Office",
                             upfrontFee:140,
                             annualFee:80,
                             setupFee:140
                         },
                         {
                            name:"Field",
                            upfrontFee:80,
                            annualFee:80,
                            setupFee:0
                        }
                   ]
                 },
                 {
                    name:"Clik NICEIC",
                    color:"#c91242",                    
                    hasFirstTwoLicenceAnnualFee:false,
                    firstTwoLicenceAnnualFee:0,
                    licences:[
                         {
                             name:"Office",
                             subtext:"Initial licence £281, additional £141",
                             upfrontFee:141,
                             annualFee:80,
                             setupFee:140
                         }
                   ]
                 },
                 {
                    name:"Clik Cert Electrical",
                    color:"#C91341",                    
                    hasFirstTwoLicenceAnnualFee:false,
                    firstTwoLicenceAnnualFee:0,
                    licences:[
                         {
                             name:"Field",
                             coverSubtext:"First field licence included with office cover",
                             upfrontFee:0,
                             annualFee:80,
                             firstYearCoverFree:true,
                             setupFee:0
                         }
                   ]
                 } ,
                 {
                    name:"AC Calc",
                    color:"#fabe1c",                    
                    hasFirstTwoLicenceAnnualFee:false,
                    firstTwoLicenceAnnualFee:0,
                    licences:[
                         {
                             name:"Office",
                             upfrontFee:400,
                             annualFee:0,
                             setupFee:0
                         }
                   ]
                 } ,
                 {
                    name:"Training",
                    color:"#663399",                    
                    hasFirstTwoLicenceAnnualFee:false,
                    firstTwoLicenceAnnualFee:0,
                    licences:[
                         {
                             name:"On Site - Full Day",
                             upfrontFee:800,
                             annualFee:0,
                             setupFee:0,
                             bespokeCountTag:"per day"
                         },
                         {
                            name:"On Site - Half Day",
                            upfrontFee:500,
                            annualFee:0,
                            setupFee:0,
                            bespokeCountTag:"per half day"
                        }
                        ,
                         {
                            name:"In House- Full Day",
                            upfrontFee:650,
                            annualFee:0,
                            setupFee:0,
                            bespokeCountTag:"per day"
                        }
                        ,
                         {
                            name:"In House - Half Day",
                            upfrontFee:350,
                            annualFee:0,
                            setupFee:0,
                            bespokeCountTag:"per half day"
                        }
                        ,
                         {
                            name:"Online 1 hour",
                            upfrontFee:80,
                            annualFee:0,
                            setupFee:0,
                            bespokeCountTag:"per hour"
                        }
                   ]
                 }                   
            ];
    }
 
    function simplifiedDivision(a,b){
        if (b == 0){
            return 0;
        }
        return(Math.round(a/b));
    }

    function updateSelectedLicenceNumber(valueChange,productIndex,licenceIndex){
           var updatingProducts = [...selectableProducts];
           var updatingProduct = updatingProducts.find(product =>{
                return product.index == productIndex;
           });
           var updatingLicence = updatingProduct.licences.find(licence =>{
                return licence.index == licenceIndex;
           });
           var newUserNumber =  updatingLicence.selectedUsers + valueChange;
          
           if (newUserNumber > 0){
                updatingLicence.calculatedUpfrontFee = (newUserNumber * updatingLicence.upfrontFee) + updatingLicence.setupFee;
                updatingLicence.calculatedCoverFee = newUserNumber * updatingLicence.annualFee;
                if (updatingLicence.firstYearCoverFree != undefined && updatingLicence.firstYearCoverFree){
                    updatingLicence.calculatedCoverFee -= updatingLicence.annualFee;
                }
                if (updatingProduct.hasFirstTwoLicenceAnnualFee){
                    if ( newUserNumber <= 2){
                        updatingLicence.calculatedCoverFee = updatingProduct.firstTwoLicenceAnnualFee;
                    }
                    else{
                        updatingLicence.calculatedCoverFee = updatingProduct.firstTwoLicenceAnnualFee +  ((newUserNumber-2) * updatingLicence.annualFee);
                    }
                }
                updatingLicence.selectedUsers = newUserNumber;
           }
           if (newUserNumber <= 0){
                updatingLicence.calculatedUpfrontFee = 0;
                updatingLicence.calculatedCoverFee = 0;
                updatingLicence.selectedUsers = 0;
           }
           var newTotalUpfront = 0;
           var newTotalAnnualCover = 0;
           var newTotalUsers = 0;
           for(var i = 0; i < updatingProducts.length; i ++){
               var product = updatingProducts[i];
               for (var j = 0; j < product.licences.length;j++){
                   newTotalUpfront += product.licences[j].calculatedUpfrontFee;
                   newTotalUsers += product.licences[j].selectedUsers;
                   newTotalAnnualCover += product.licences[j].calculatedCoverFee;
               }
           }
           setTotalUsers(newTotalUsers);
           setTotalUpfront(newTotalUpfront);
           setTotalAnnualCover(newTotalAnnualCover);
           setSelectableProducts(updatingProducts);
    }

    return (
       <div className="clik-calculator">
           <div className="top-row">
               <div className="left-section"></div>
               <div className="right-section">
                    <div className="switch-container">
                            <div className= {`switch-label ${!isDisplayingMonthly ? "active" : ""}`}>Annually</div>
                                <Switch
                                     color="primary"
                                    checked={isDisplayingMonthly}
                                    onChange={(event) =>{setIsDisplayingMonthly(!isDisplayingMonthly);}}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            <div className= {`switch-label ${isDisplayingMonthly ? "active" : ""}`}>Monthly</div>
                    </div>
               </div>
              
                   
           </div>
           <div className="headings-row">
               <div className="left-section">
                    <div className="heading-section left-aligned">
                        Software
                    </div>
               </div>
               <div className="right-section">
                    <div className="heading-section left-aligned">
                        Licence Type
                    </div>
                    <div className="heading-section">
                        Users
                    </div>
                    <div className="heading-section">
                        Licence Fee
                        <br/>
                            <span className="subtext">Upfront</span>
                    </div>
                    <div className="heading-section">
                        Clik Cover
                        <br/>
                            <span className="subtext">Paid Annually</span>
                    </div>
               </div>
               
           </div>
            {
                selectableProducts.map((product)=>{
                    return(
                        <div className={`product-row ${product.isHighlighted ? "highlighted":""}`}>
                            <div className="title-section">
                                <div className="title-section-container">
                                    <div className="product-circle" style={{backgroundColor:product.color}}>                                 
                                   </div>
                                   {product.name}
                                </div>
                              
                            </div>
                            <div className="licences-section">
                                {
                                    product.licences.map((licence)=>{
                                        var totalCoverSection;
                                        if (isDisplayingMonthly){
                                            totalCoverSection = (
                                                <div className="total-section">
                                                    <div className="top">  £{simplifiedDivision(licence.calculatedCoverFee,12)}  </div>
                                                    <div className="bottom">  (£{simplifiedDivision(licence.calculatedCoverFee/12,licence.selectedUsers)} {licence.bespokeCountTag != undefined ? licence.bespokeCountTag  : 'per user'} )  </div> 
                                                    <div className={`licence-subtext ${licence.coverSubtext === undefined || licence.coverSubtext === "" ? "hidden" : ""}`} >*{licence.coverSubtext}</div>   
                                                </div> 
                                            );
                                          
                                        }else{
                                            totalCoverSection = (
                                                <div className="total-section">
                                                    <div className="top">  £{licence.calculatedCoverFee}  </div>
                                                    <div className="bottom">  (£{simplifiedDivision(licence.calculatedCoverFee,licence.selectedUsers)} per user)  </div>
                                                    <div className={`licence-subtext ${licence.coverSubtext === undefined || licence.coverSubtext === "" ? "hidden" : ""}`} >*{licence.coverSubtext}</div>    
                                                </div>                                             
                                            );
                                        }

                                        return(
                                            <div className={`licence-row ${licence.selectedUsers>0 ? "" : "inactive"}`}>  
                                                 <div className="licence-row-section left-aligned">
                                                    {licence.name}     
                                                </div>
                                                <div className="licence-row-section">
                                                    <div className="user-update-button" onClick={() => updateSelectedLicenceNumber(-1,product.index,licence.index)}>
                                                        -
                                                    </div>
                                                    <div>
                                                        {licence.selectedUsers}
                                                        
                                                    </div>
                                                    <div className="user-update-button"  onClick={() => updateSelectedLicenceNumber(1,product.index,licence.index)}>
                                                        +
                                                    </div>
                                                </div>
                                                <div className="licence-row-section">
                                                    <div className="total-section">
                                                        <div className="top">  £{licence.calculatedUpfrontFee} {licence.subtext != undefined && licence.subtext!= "" ? "*" : ""}   </div>
                                                        <div className="bottom">  (£{simplifiedDivision(licence.calculatedUpfrontFee,licence.selectedUsers)} {licence.bespokeCountTag != undefined ? licence.bespokeCountTag  : 'per user'})  </div>
                                                        <div className={`licence-subtext ${licence.subtext === undefined || licence.subtext === "" ? "hidden" : ""}`} >*{licence.subtext}</div>   
                                                    </div> 
                                                                                                    
                                                </div>
                                                <div className="licence-row-section blue-section">
                                                    {totalCoverSection}
                                                </div>
                                                
                                            </div>
                                        );
                                    })
                                }
                            </div>
                          
                        </div>
                        
                    );
                })
            }
            <div className="totals-row">
                <div className="totals-row-section">
                    <div className="top">
                        £{totalUpfront}
                    </div>
                    <div className="bottom">
                        (One off upfront)
                    </div>
                </div>
                <div className= {`totals-row-section blue-section ${isDisplayingMonthly ? "hidden" : ""}`}>
                    <div className="top">
                        £{totalAnnualCover}
                    </div>
                    <div className="bottom">
                       (£{simplifiedDivision(totalAnnualCover,totalUsers)} per user)
                    </div>
                    
                </div>
                <div className= {`totals-row-section blue-section ${!isDisplayingMonthly ? "hidden" : ""}`}>
                    <div className="top">
                        £{simplifiedDivision(totalAnnualCover,12)}
                    </div>
                    <div className="bottom">
                       (£{simplifiedDivision(totalAnnualCover/12,totalUsers)} per user)
                    </div>
                    
                </div>
            </div>
            <p class="footnote">*all prices quoted are VAT exclusive</p>
       </div>
    );
}



export default ClikCalculator